import { HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  [x: string]: HttpClient | any;

  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders();
    this.httpHeaders.set('Content-Type', 'application/json');
  }

  get(url: string, params?: HttpParams): Observable<any> {
    return this.http.get(url, { headers: this.httpHeaders, params: params });
  }

  delete(url: string): Observable<any> {
    return this.http.delete(url, { headers: this.httpHeaders });
  }

  put(url: string, request: any, headers?: HttpHeaders): Observable<any> {
    let _header = headers ?? this.httpHeaders;
    return this.http.put(url, request, { headers: _header });
  }

  post(url: string, request: any, headers?: HttpHeaders): Observable<any> {
    let _header = headers ?? this.httpHeaders;
    return this.http.post(url, request, { headers: _header });
  }

}
