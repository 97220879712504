import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withHashLocation } from '@angular/router';
import { CommonModule } from '@angular/common';


import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CalAngularModule } from '@cvx/cal-angular';
import { environment } from '../environments/environment';
import { AuthInterceptor } from './shared/interceptor/auth.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideRouter(routes, withHashLocation()), 
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    CommonModule,
    importProvidersFrom(CalAngularModule.forRoot({
      autoSignIn: true,
      popupForLogin: false,
      instance: "https://login.microsoftonline.com/",
      tenantId: environment.tenantId,
      clientId: environment.clientId,
      redirectUri: environment.UIbaseURL,
      oidcScopes: ["openid", "profile", "User.Read", "offline_access"],
      graphScopes: ["User.Read", "profile"],
      cacheLocation: "sessionStorage"
    }))
  ],

};

