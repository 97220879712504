import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { HomeService } from '../../services/home.service';
import { CommonModule, Location, NgClass } from "@angular/common";
import { DialogService } from '../../services/dialog.service';


@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, CommonModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent {
  currentUserProfile: any;
  securityUsersAccess: any[];
  //currentUserProfile: ICvxClaimsPrincipal | null = null;
  displayLogin: boolean = false;

  constructor(private homeService: HomeService, location: Location, private dialogService: DialogService, public router: Router) {
    this.securityUsersAccess =  localStorage.getItem('securityUsersAccess') ? JSON.parse(localStorage.getItem('securityUsersAccess') || '{}') : [];
  }

  printUserName(){
    //this.currentUserProfile = this.homeService.getCurrentUserProfile();
    this.currentUserProfile = localStorage.getItem('currentUserProfile') ? JSON.parse(localStorage.getItem('currentUserProfile') || '{}') : null;
    return this.currentUserProfile?._name;
  }

  verifyAccess(){
    this.securityUsersAccess.forEach((element) => {
      //if(element.securityUserEmail.toLocaleLowerCase() === this.homeService.getCurrentUserProfile()?.email.toLocaleLowerCase()){
      if(element.securityUserEmail.toLocaleLowerCase() === this.currentUserProfile?._email.toLocaleLowerCase()){
        this.displayLogin = true;
      }
    });

    if(!this.displayLogin){
      this.dialogService.openAlertDialog("Not authorized to access this page", true);
    }else{
      this.router.navigate(['/admin'])
    }
  }
  
}
