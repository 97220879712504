import { Injectable } from '@angular/core';
import { ICvxClaimsPrincipal } from '@cvx/cal-angular';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  entryPoint: string;
  investigateInfo: any[];
  securityUsersAccess: any[];
  currentUserProfile: ICvxClaimsPrincipal;

  constructor() { }

  setEntryPoint(entryPoint: string){
    this.entryPoint = entryPoint;
  }

  getEntryPoint(){
    return this.entryPoint;
  }

  setCurrentUserProfile(currentUserProfile: ICvxClaimsPrincipal){
    this.currentUserProfile = currentUserProfile;
  }

  getCurrentUserProfile(){
    return this.currentUserProfile;
  }

  getInvestigateInfo(){
    return this.investigateInfo;
  }
  setInvestigateInfo(array: any[]){
    this.investigateInfo = array;
  }

  getSecurityUsersAccess(){
    return this.securityUsersAccess;
  }
  setSecurityUsersAccess(array: any[]){
    this.securityUsersAccess = array;
  }
}
