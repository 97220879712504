
<div class="container_asset">
    <nav>
        <!-- <img src="../../assets/img/image.png" alt=""> -->
    </nav>
    
    <p class="checkingpara"><span style="color: #009DD9;">CHECK-IN</span> : Validate device</p>
    <hr>
    
    <form (ngSubmit)="onSubmitSerial(form)" #form="ngForm">
        <!-- <label for="">Serial Number</label><br /> -->
        <input 
        pattern="^[a-zA-Z0-9]+$"
        required 
        maxlength="20" 
        #myInputSerialNumber="ngModel" 
        #myInputReset
        (keydown)="handleKeydown($event)" 
        type="text" 
        ngModel 
        name="serialNumber" 
        autofocus 
        placeholder="Enter Serial Number">
        <button class="validate" type="submit">Validate</button>
        <button type=reset (click)= 'resetForm(form)' class="reset">Reset</button><br />
        <!-- @if(myInputSerialNumber.invalid && myInputSerialNumber.touched){
          <span style="color: red;font-size: 12px;" class="help-block">Invalid serial number</span>
        } -->
    </form>

    <div class="container_output">
      @for (obj of resultConsult; track obj.id) {
        <p class="Outputlabel">User information: </p>
        <label class="Outputlabel">Serial Number:</label> <span> {{obj.serialnumber}}</span><br>
        <label class="Outputlabel">Assigned User:</label> <span> {{obj.assignedtoValue}}</span><br>
        <label class="Outputlabel">Alternate / Supervisor: </label> <span> {{obj.managedbyValue}}</span><br>
        <label class="Outputlabel">Device Model: </label> <span> {{obj.modelValue}}</span><br>
        <label class="Outputlabel">Device Status: </label> <span> {{obj.state}}</span>
      }
      @empty {
          <!-- <p [style]="stateFound ? { 'color': 'red', 'font-size': '20px' } : { 'color': '' }">No data found. Please verify the serial number.</p> -->
          @if(myInputSerialNumber.invalid && myInputSerialNumber.touched){
            <!-- <span style="color: red;font-size: 12px;" class="help-block">Invalid serial number</span> -->
          }@else if (stateFound) {
            <p style="color: red; font-size: 20px">No data found. Please verify the serial number.</p>
          }
      }  
    </div>
    
    <div class="Check-inSection">
      <button type="submit" mat-raised-button [disabled]="myInputSerialNumber.invalid" (click)="onCheckin()" class="check-in">Check-in</button>
      <button type="" class="investigate reset" [disabled]="myInputSerialNumber.invalid" (click)="onInvestigate()">Investigate</button>
    </div>
    
</div>
