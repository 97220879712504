import { Component } from '@angular/core';

import { HomeService } from '../../services/home.service';
import { FormsModule } from '@angular/forms';
import { DialogService } from '../../services/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AssetServiceService } from '../../services/asset-service.service';

@Component({
  selector: 'app-entry-point',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './entry-point.component.html',
  styleUrl: './entry-point.component.css'
})
export class EntryPointComponent {
  entryPoint: string;
  securityCheckPoint : any[];

  constructor(private homeService: HomeService, private dialogService: DialogService, public dialogRef: MatDialogRef<EntryPointComponent>, private assetServiceService: AssetServiceService){
    this.getAllSecurityCheckPoint();
  }

  ChangeEntryPoint(){
    if(this.entryPoint !== undefined){
      this.homeService.setEntryPoint(this.entryPoint)
      localStorage.setItem('securityCheckPointSelected', this.entryPoint);
      console.log(localStorage.getItem('securityCheckPointSelected'));
      
      this.dialogRef.close(); 
    }else{

    }
  }

  getAllSecurityCheckPoint() {
    this.assetServiceService.getAllSecurityCheckPoint()
      .subscribe({
        next: (data) => {
          this.securityCheckPoint = data;
          
        },
        error: (error) => {
          console.error('Error:', error);
        },
        complete: () => {
          //console.log('Request complete');
        }
      });
  }
}
