export class SourceData {

    arrayOfdata = [
        {
            "sysId": "fa91644e1b91ccd03061624d6e4bcb59",
            "serialNumber": "TRF4450T17",
            "assettag": "TRF4450T17",
            "derivedBarcode": "wer C8M87ATRF4450T17",
            "modelValue": "Hewlett-Packard EliteDesk 800 G1 Tower C8M87AV",
            "assignedtoValue": null,
            "managedbyValue": "Jorge Sumbo",
            "state": "Retired",
            "substate": "Disposed",
            "supportgroupValue": "SASBU - IT EQUIPMENT REQUEST SUPPORT"
        }
    ]
}