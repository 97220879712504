<nav class="navbar bg-body-tertiary">
    <form class="container-fluid">
        <div>
            <button routerLink="/" ariaCurrentWhenActive="page" class="btn btn-outline-success me-2" type="button"><i style="font-size: 40px;" class="bi bi-house"></i></button>
            <button routerLink="/check-in" routerLinkActive="active" ariaCurrentWhenActive="page" class="btn btn-outline-success me-2" type="button">Check-in</button>
            <button routerLink="/check-out" routerLinkActive="active" ariaCurrentWhenActive="page" class="btn btn-outline-success me-2" type="button">Check-out</button>
            <a target="_blank" href="https://forms.office.com/Pages/DesignPageV2.aspx?rpring=Business&rpsession=8f61f3bb-c66d-4576-9188-b757de729213&subpage=design&id=oZ15_cG_NEKpHHKzocueJh5H8F8m52lMjutFpVZq6yZUNzJCMjc0VFRIUUI5T0dEOTFIN1JGUEwxUC4u" class="btn btn-l btn-outline-secondary" type="button">Registration</a>
        </div>
        <div>
            <p class="userName">{{printUserName()}}</p>
            <button [ngStyle]="{'display': displayLogin}" routerLink="/admin" routerLinkActive="active" ariaCurrentWhenActive="page" class="btn btn-outline-success me-2 login" type="button"><i class="bi bi-box-arrow-in-right"></i></button>
            <!-- <button title="Sign in" (click)="verifyAccess()" routerLinkActive="active" ariaCurrentWhenActive="page" class="btn btn-outline-success me-2 login" type="button"><i class="bi bi-box-arrow-in-right"></i></button> -->
        </div>    
    </form>
    <a ><img src="../../assets/img/chevron.png" alt="" width="30"></a>
</nav>
<p></p>


