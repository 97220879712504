<div class="modal1">

    <div class="row mb-2">
        <p class="entryPoint">Select entry point</p>
        <div class="container_gate">
            <select required class="form-select form-select-sm" aria-label="Small select example" [(ngModel)]="entryPoint">
                <option disabled value="0">Security check Point</option>
                @for (checkPoint of securityCheckPoint; track checkPoint) {
                  <option [value]="checkPoint.checkPointId">{{checkPoint.checkPointName}}</option>
                }
              </select>
        </div>
    </div>
  
    <div class="row mt-2 d-flex justify-content-end">
      <div class="col">
        <button type="button" class="btn alert-btn-primary float-end" (click)="ChangeEntryPoint()">OK</button>
      </div>
    </div>
  
  </div>
  
