<div class="modal1">

    <div class="row mb-2">
      <div class="col alert-title text-center">
        <h2 class="fs-6">{{title}}</h2>
      </div>
    </div>
    @for(investigation of investigationReasonArray; track investigation.investigationReasonId){
      <div class="form-check">
        <input [value]="investigation.investigationReasonId" (change)="onChangeInvestigateReason(investigation)" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
        <label class="form-check-label" for="flexRadioDefault1">
          {{investigation.investigationReason1}}
        </label>
      </div>
    }
    <div>
      <textarea [(ngModel)]="otherReason" [disabled]="disabledOtherReason" class="form-control" name="" id="" rows="3" cols="3"></textarea>
    </div>
    

    <div class="row mt-2 d-flex justify-content-end">
      <div class="col">
        <button type="button" class="btn alert-btn-primary float-end" (click)="OnClose()">Cancel</button>
         <button type="button" class="btn alert-btn-primary float-end" (click)="sendInvetigation()">Send</button>
        
      </div>
    </div>
  
  </div>
  
