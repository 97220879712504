import { Component } from '@angular/core';
import { NavbarComponent } from "../laptop/navbar/navbar.component";
import { AdminNavbarComponent } from './admin-navbar/admin-navbar.component';
import { AdminCheckInComponent } from "./admin-check-in/admin-check-in.component";
import { AdminInvestigateComponent } from "./admin-investigate/admin-investigate.component";
import { Router, RouterOutlet } from '@angular/router';
import { HomeService } from '../services/home.service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [AdminNavbarComponent, AdminCheckInComponent, AdminInvestigateComponent, RouterOutlet],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.css'
})
export class AdminComponent {

  displayLogin: boolean = false;
  securityUsersAccess: any[];
  currentUserProfile: any;

  constructor(private homeService: HomeService, private dialogService: DialogService, private router: Router) {
    
    this.securityUsersAccess =  localStorage.getItem('securityUsersAccess') ? JSON.parse(localStorage.getItem('securityUsersAccess') || '{}') : [];
    this.currentUserProfile = localStorage.getItem('currentUserProfile') ? JSON.parse(localStorage.getItem('currentUserProfile') || '{}') : null;

    this.securityUsersAccess.forEach((element) => {
        if(element.securityUserEmail.toLocaleLowerCase() === this.currentUserProfile?._email.toLocaleLowerCase()){
          this.displayLogin = true;
        }
      });
      if(!this.displayLogin){
        this.router.navigate(['/']);
      }  
      

      
  }
}
