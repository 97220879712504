<div class="home_container">
    <app-navbar></app-navbar>
    <!-- <div class="container_gate">
        <select required class="form-select form-select-sm" aria-label="Small select example" [(ngModel)]="entryPoint" (change)="ChangeEntryPoint()">
            <option selected>Security check Point</option>
            <option value="4">Hotel Malongo</option>
            <option value="5">Malongo Dock</option>
            <option value="1">Gate 1</option>
            <option value="3">Gate 2</option>
          </select>
    </div> -->
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</div>
