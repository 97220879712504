import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAssetMovement, InvestigationReason } from '../../Models/asset-movement.model';
import { DialogService } from '../../services/dialog.service';
import { HomeService } from '../../services/home.service';
import { AssetServiceService } from '../../services/asset-service.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-investigate-dialog',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './investigate-dialog.component.html',
  styleUrl: './investigate-dialog.component.css'
})
export class InvestigateDialogComponent 
{
  title: string = "";
  message: string = "";
  isError: boolean = false;
  assetReasoncheckbox: InvestigationReason;
  otherReason: string;
  investigationReasonArray: InvestigationReason[];
  disabledOtherReason: boolean;
  currentUserProfile: any;
  securityCheckPoint: any;


  constructor(private assetServiceService: AssetServiceService, private homeService: HomeService, private dialogService: DialogService, public dialogRef: MatDialogRef<InvestigateDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.BindDetail(data);
    }
    this.getInvestigationReason();
    this.disabledOtherReason = true;
    
  }
  
  getInvestigationReason(){
    return this.assetServiceService.getInvestigationReason().subscribe(
      {
        next: (data) => {
          this.investigationReasonArray = data;
        },
        error: (error) => {
          console.error('Error:', error);
        },
        complete: () => {
          //console.log('Request complete');
        }
      }
    )
  }

  BindDetail(data: any) {
    this.isError = data.isError;
    this.title = data.isError ? "Error" : "Investigation";
    this.message = data.message;
  }

  OnClose() {
    this.dialogRef.close();
  }

  onChangeInvestigateReason(investigationReason: InvestigationReason){
    this.assetReasoncheckbox = investigationReason;
    this.disabledOtherReason = true;
    if(investigationReason.investigationReason1.toLowerCase() == 'OTHER'.toLowerCase()){
      this.disabledOtherReason = false;
    }
  }

  sendInvetigation(){

    let otherOptionSelected = this.investigationReasonArray.find(e=>e.investigationReason1.toLowerCase() == 'OTHER'.toLowerCase());
    this.currentUserProfile = localStorage.getItem('currentUserProfile') ? JSON.parse(localStorage.getItem('currentUserProfile') || '{}') : null;
    this.securityCheckPoint = localStorage.getItem('securityCheckPointSelected');
    
    let assetMovement: IAssetMovement = {
      AssetSerialNumber: this.homeService.getInvestigateInfo()[0].serialnumber,
      AssetOwner1: this.homeService.getInvestigateInfo()[0].assignedtoValue,
      AssetOwner2: this.homeService.getInvestigateInfo()[0].managedbyValue,
      MovementTypeId: 3, //investigate
      SecurityOperator: this.currentUserProfile._name,
      CheckPointId: Number.parseInt(this.securityCheckPoint), //hotel Malongo
      AssetInvestigationReason: [{
        AssetInvestigationComments: this.assetReasoncheckbox.investigationReasonId == otherOptionSelected?.investigationReasonId ? this.otherReason : this.assetReasoncheckbox.investigationReason1,
        InvestigationReasonId: this.assetReasoncheckbox.investigationReasonId
      }]
    }

    this.assetServiceService.saveAssetMovement(assetMovement)
    .subscribe({
      error: (error) =>{
        console.log("Error: ", error);
        const dialogRef = this.dialogService.openAlertDialog("Error on load", false);
          dialogRef.afterClosed().subscribe(res => {
          if (res && res.flag) {
              
            }
          });
      },
      complete: () => {
        console.log('Request complete');
        const dialogRef = this.dialogService.openAlertDialog("Investigation Sent", false);
        this.OnClose()
        }
      })
  }
}
