import { Component,  ViewChild, ElementRef } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';

import { SourceData } from "../../services/data.service";
import { CheckInComponent } from "../check-in/check-in.component";
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { NavbarComponent } from '../navbar/navbar.component';
import { FooterComponent } from '../footer/footer.component';
import { HomeService } from '../../services/home.service';
import { HomePageComponent } from "../home-page/home-page.component";
import { DialogService } from '../../services/dialog.service';



@Component({
  selector: 'app-home',
  standalone: true,
  imports: [FormsModule, CheckInComponent, RouterOutlet, HomeComponent, CheckInComponent, NavbarComponent, FooterComponent, HomePageComponent, RouterOutlet],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
  providers: [SourceData]
})
export class HomeComponent {
  //entryPoint: string;
  loggedUserName: string;

  constructor(private homeService: HomeService, private dialogService: DialogService){
    if(localStorage.getItem('securityCheckPointSelected') != null){
      
    }else{
      const dialogRef = this.dialogService.EntryPointDialog("", false);
    }
    
  }

  ngInit(){

  }

  // ChangeEntryPoint(){
  //   this.homeService.setEntryPoint(this.entryPoint)
  // }




}
