export const environment = {
  name: "dev",
  production: false,
  baseURL: "https://sasbulaptops-api-dev.azure.chevron.com",
  UIbaseURL: "https://sasbulaptops-dev.azure.chevron.com",
  clientId: "1851969a-2e48-44a6-8c97-b80e7abf774c",
  tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  endpointScopes: ['1851969a-2e48-44a6-8c97-b80e7abf774c/.default'],
  appInsights: {
    instrumentationKey: "8afafa3b-b9fe-4864-89e7-1ac3b1b270b28afafa3b-b9fe-4864-89e7-1ac3b1b270b2"
}

};
