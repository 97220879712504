<nav class="navbar bg-primary">
    <form class="container-fluid justify-content-start">
        <button routerLink="/admin" ariaCurrentWhenActive="page" class="btn btn-outline-success me-2" type="button"><i style="font-size: 40px;" class="bi bi-house"></i></button>
        <button routerLink="admin-checkin" routerLinkActive="active" ariaCurrentWhenActive="page" class="btn btn-outline-primary me-2" type="button">Check-in data</button>
        <button routerLink="admin-checkout" routerLinkActive="active" ariaCurrentWhenActive="page" class="btn btn-outline-primary me-2" type="button">Check-out data</button>
        <button routerLink="admin-investigate" routerLinkActive="active" ariaCurrentWhenActive="page" class="btn btn-outline-primary me-2" type="button">Cases under Investigation</button>
    </form>
    <img src="../../../assets/img/chevron_logo.png" alt="" width="30">
</nav>
<p class="userName">{{printUserName()}}</p>

