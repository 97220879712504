import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, catchError, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AssetServiceService {

  constructor(private http: HttpService, private http_: HttpClient) { }

  saveAssetMovement(request: any): Observable<any> {
    return this.http.post(`${environment.baseURL}/api/AssetMovement/SaveAssetMovement/`, request);
  }

  getAsset(assetSerialNumber: any): Observable<any> {
    return this.http_.get(`${environment.baseURL}/api/AssetMovement/GetAsset/${assetSerialNumber}`)
    .pipe(
      catchError(this.handleError)
    )

  }

  getInvestigationReason(): Observable<any>{
    return this.http_.get(`${environment.baseURL}/api/Investigation/GetInvestigationReasons/`)
    .pipe(
      catchError(this.handleError)
    )
  }

  // getAssetMovementType(movementTypeId: number): Observable<any>{
  //   return this.http_.get(`${environment.baseURL}/api/AssetMovement/GetAssetMovementType/${movementTypeId}`)
  //   .pipe(
  //     catchError(this.handleError)
  //   )
  // }

  getAssetMovementType(movementTypeId: number, filters: any): Observable<any> {
    let params = new HttpParams();
    // Add filters to the params
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        params = params.set(key, filters[key]);
      }
    }
  
    return this.http_.get(`${environment.baseURL}/api/AssetMovement/GetAssetMovementType/${movementTypeId}`, { params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getSecurityCheckPoint(checkPointId: number): Observable<any>{
    return this.http_.get(`${environment.baseURL}/api/SecurityCheckPoint/GetSecurityCheckPoint/${checkPointId}`)
    .pipe(
      catchError(this.handleError)
    )
  }

  getAllSecurityCheckPoint(): Observable<any>{
    return this.http_.get(`${environment.baseURL}/api/SecurityCheckPoint/GetAllSecurityCheckPoint/`)
    .pipe(
      catchError(this.handleError)
    )
  }

  getAllSecurityUsersAccess(): Observable<any>{
    return this.http_.get(`${environment.baseURL}/api/SecurityUsersAccess/GetAllSecurityUsersAccess/`)
    .pipe(
      catchError(this.handleError)
    )
  }

  // private handleError(error: HttpErrorResponse) {
  //   if (error.status === 0) {
  //     return (error.error);
  //   } else {
  //     console.error(
  //       `Backend returned code ${error.status}, body was: `, error.error);
  //   }
  //   return throwError(() => new Error('Something bad happened; please try again later.'));
  // }
  private handleError(error: any): Observable<never> {
    // Handle the error here
    console.error('An error occurred:', error);
    throw error;
  }
}
