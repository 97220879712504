import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-admin-navbar',
  standalone: true,
  imports: [RouterLink, RouterLinkActive],
  templateUrl: './admin-navbar.component.html',
  styleUrl: './admin-navbar.component.css'
})
export class AdminNavbarComponent {

  currentUserProfile: ICvxClaimsPrincipal | null = null;

  constructor(private homeService: HomeService) {
    
  }
  printUserName(){
    this.currentUserProfile = this.homeService.getCurrentUserProfile();
    return this.currentUserProfile?.name;
  }
}
