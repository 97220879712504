<div class="container_asset">
    <h3>Investigation</h3>
    <hr />
    <div class="row search">
        <div class="col-3">
            <input type="text" class="form-control" (input)="getAssetMovementType(3)" [(ngModel)]="filters.serialNumber" placeholder="Filter by serial number">
        </div>
        <div class="col-3">
            <input type="text" class="form-control" (input)="getAssetMovementType(3)" [(ngModel)]="filters.assetOwner" placeholder="Filter by asset owner">
        </div>
        <div class="col-2">
            <input type="date" class="form-control" (input)="getAssetMovementType(3)" [(ngModel)]="filters.initialDate" title="Filter by start date">
        </div>
        <div class="col-2">
            <input type="date" class="form-control" (input)="getAssetMovementType(3)" [(ngModel)]="filters.finishDate" title="Filter by finish date">
        </div>
        <div class="col-1">
            <button class="btn btn-outline-secondary notActive" (click)="getAssetMovementType(3)"><i class="bi bi-search"></i> </button> 
        </div>
    </div>
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Serial Number</th>
                <th scope="col">Asset owner</th>
                <th scope="col">Investigation Comment</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            @for(investigation of InvestigationList; track investigation){
                <tr>
                    <td>{{investigation.assetSerialNumber}}</td>
                    <td>{{investigation.assetOwner1}}</td>
                    <td>{{investigation.investigationComments}}</td>
                    <td><button (click)="viewAssetInfo(investigation)" class="btn btn-outline-primary"><i class=" bi bi-eye"></i></button></td>
                </tr>
            }@empty {
                <tr><td colspan="4" style="text-align: center; font-weight: bolder; font-size: 20px;">No data found</td></tr>
            }
        </tbody>
    </table>

    <div class="card-body">
        <div class="row">
            <div class="col-2">
                <div class="dataTables_length">
                    <label>Items per page:</label>
                    <select class="form-select form-select-sm" [(ngModel)]="filters.length" (change)="getAssetMovementType(3)">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                    </select> 
                </div>
            </div>
            <div class="col-md-10" style="float:right;text-align: right">
                <div class="paginations">
                    <div>
                        <div class="btn-group" id="radioBtn"> 
                            <a class="btn btn-light btn-sm notActive" (click)="onPreview()"> <i class="bi bi-chevron-compact-left"></i> </a> 
                            <a class="btn btn-light btn-sm notActive">{{filters.pageNumber}}</a>  
                            <a class="btn btn-light btn-sm notActive" (click)="onNext()"><i class="bi bi-chevron-compact-right"></i> </a> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
