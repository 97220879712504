import { Component,  ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router, NavigationEnd } from '@angular/router';
import { AssetServiceService } from '../../services/asset-service.service';
import { HomeService } from '../../services/home.service';
import { DialogService } from '../../services/dialog.service';
import { IAssetMovement } from '../../Models/asset-movement.model';



@Component({
  selector: 'app-check-in',
  standalone: true,
  imports: [FormsModule, MatSlideToggleModule],
  templateUrl: './check-in.component.html',
  styleUrl: './check-in.component.css',
  providers: []
})
export class CheckInComponent implements AfterViewInit{

  serialNumber8value: string;
  resultConsult: any[]= [];
  assetList: any[] = [];
  dialog: any;
  stateFound: boolean;
  currentUserProfile: any;
  securityCheckPoint: any;
  
  @ViewChild('myInputSerialNumber') myInputSerialNumber: any;
  @ViewChild('myInputReset') myInputReset: any;
  
  constructor(private assetServiceService: AssetServiceService, private homeService: HomeService, private dialogService: DialogService, private router: Router) {
    this.myInputSerialNumber = ElementRef;
    this.myInputReset = ElementRef;
    this.resultConsult = [];
    this.serialNumber8value = '';

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setFocus();
      }
    })
  }

  ngOnInit(): void {
    //this.serialNumber = this._sourceData.arrayOfdata[0].serialNumber;
    //this.getAssetSerialNumber('TRF4450T17');
  }

  ngAfterViewInit() {
    this.setFocus();
  }

  setFocus() {
    if (this.myInputReset) {
      this.myInputReset.nativeElement?.focus();
    }
  }

  onSubmitSerial(form: any){
    
    if(form.invalid){
      return;
    }

    this.getAssetSerialNumber(form.value.serialNumber);

     if(this.resultConsult.length == 0){
      this.stateFound = true; 
     }

  }

  resetForm(form: any){
    form.value.serialNumber = "";
    this.myInputReset.nativeElement.focus();
    this.resultConsult = [];
    this.stateFound = false;
  }
  reset(){
    this.myInputReset.nativeElement.value = "";
    this.resultConsult = [];
    this.stateFound = false;
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.myInputReset.nativeElement.value = '';
      this.resultConsult = [];
      this.stateFound = false;
    }
  }

  onCheckin(){
    
    if (this.resultConsult.length > 0) {
      this.currentUserProfile = localStorage.getItem('currentUserProfile') ? JSON.parse(localStorage.getItem('currentUserProfile') || '{}') : null;
      this.securityCheckPoint = localStorage.getItem('securityCheckPointSelected');

      let assetMovement: IAssetMovement = {
        AssetSerialNumber: this.resultConsult[0].serialnumber,
        AssetOwner1: this.resultConsult[0].assignedtoValue,
        AssetOwner2: this.resultConsult[0].managedbyValue,
        MovementTypeId: 1, //checkin
        SecurityOperator: this.currentUserProfile?._name,
        CheckPointId: Number.parseInt(this.securityCheckPoint), //hotel Malongo
        AssetInvestigationReason: null
      }

        this.assetServiceService.saveAssetMovement(assetMovement)
        .subscribe({
          error: (error) =>{
            console.log("Error: ", error);
            const dialogRef = this.dialogService.openAlertDialog("Error on load", false);
              dialogRef.afterClosed().subscribe(res => {
              if (res && res.flag) {
                  
                }
              });
          },
          complete: () => {
            console.log('Request complete');
            const dialogRef = this.dialogService.openAlertDialog("Check-in completed", false);
            this.reset();
            }
          }) 
    }else{
      this.stateFound = true
    }
    this.setFocus();
  }

  onInvestigate(){
    this.homeService.setInvestigateInfo(this.resultConsult);
    const dialogRef = this.dialogService.investigateDialog("", false)
  }

  getAssetSerialNumber(assetNumb: any) {
    this.assetServiceService.getAsset(assetNumb)
      .subscribe({
        next: (data) => {
          this.resultConsult = data;
          //console.log(data);
        },
        error: (error) => {
          console.error('Error:', error);
        },
        complete: () => {
          console.log('Request complete');
        }
      });
  }

}

