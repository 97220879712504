<div class="container_asset">
    <p class="text-center" style="font-weight: bolder; font-size: 25px;">Welcome to SASBU Electronic Asset Security Management</p>
    <p class="text-center" style="font-weight: bolder; font-size: 16px;">Admin Section</p>
    <!--<p class="text-center" style="font-weight: bolder; font-size: 16px;">Today's Operation</p>
     <div class="row align-items-center">
        <div class="col-4">
            <div>Check-in</div>
            <p>2</p>
        </div>
        <div class="col-4">
            <div>Check-out</div>
            <p>{{checkoutCount}}</p>
        </div>
        <div class="col-4">
            <div>Investigation</div>
            <p>9</p>
        </div>
    </div> -->
</div>
