import { Component } from '@angular/core';
import { AssetServiceService } from '../../services/asset-service.service';
import { DialogService } from '../../services/dialog.service';
import { AdminService } from '../../services/admin.service';
import { FormsModule } from '@angular/forms';
import { isEmpty } from 'rxjs';

@Component({
  selector: 'app-admin-check-in',
  standalone: true,
  imports: [ FormsModule],
  templateUrl: './admin-check-in.component.html',
  styleUrl: './admin-check-in.component.css'
})
export class AdminCheckInComponent {
  /**
   *
   */
  checkinList: any[];
  filters = {
    length: 5,
    pageNumber: 1,
    serialNumber: "",
    assetOwner: "",
    initialDate: "",
    finishDate: ""
  };
  
  constructor(private assetServiceService: AssetServiceService, private dialogService: DialogService, private adminService: AdminService) {
    this.getAssetMovementType(1);
  }

  viewAssetInfo(assetInfo: any){
    this.adminService.setassetInfo(assetInfo);
    const dialogRef = this.dialogService.viewAssetMovementInfo("", false)
  }

  onPreview(){
    if(this.filters.pageNumber > 1){
      this.filters.pageNumber--;
    }
    this.getAssetMovementType(1);
  }

  onNext(){
    if(this.checkinList.length != 0){
      this.filters.pageNumber++;
    }
    this.getAssetMovementType(1);
  }

  getAssetMovementType(movementTypeId: number){
    debugger
    this.adminService.setFilter(this.filters);
    return this.assetServiceService.getAssetMovementType(movementTypeId, this.filters).subscribe(
      {
        next: (data) => {
          this.checkinList = data;
          
        },
        error: (error) => {
          console.error('Error:', error);
        },
        complete: () => {
          //console.log('Request complete');
        }
      }
    )
  }

}
