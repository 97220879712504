<div class="modal1">

  <div class="row mb-2">
    <div class="col alert-title">
      <h2>{{title}}</h2>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <span>{{message}}</span>
    </div>
  </div>

  <div class="row mt-2 d-flex justify-content-end">

    <div class="col-auto">
      <button type="button" class="btn btn-outline-dark alert-btn-focus" (click)="OnCancel()">Cancel</button>
    </div>

    <div class="col-auto ms-auto">
      <button type="button" class="btn alert-btn-primary float-end" (click)="OnConfirm()">Yes</button>
    </div>

    <div class="col-auto">
      <button type="button" class="btn btn-outline-secondary float-end" (click)="OnClose()">No</button>
    </div>

  </div>

</div>
