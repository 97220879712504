import { ChangeDetectorRef, Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { HomeComponent } from './laptop/home/home.component';
import { CheckInComponent } from './laptop/check-in/check-in.component';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { HomeService } from './services/home.service';
import { LoaderService } from './services/loader.service';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { DialogService } from './services/dialog.service';
import { AssetServiceService } from './services/asset-service.service';



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet, HomeComponent, CheckInComponent, RouterLink, RouterLinkActive],

templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'ClientApp';
  currentUserProfile: ICvxClaimsPrincipal | null = null;
  loggedIn: boolean = false;

  loaderChangeSubject: Subscription;
  IsLoading: boolean = false;
  securityUsersAccess: any[];

  constructor( private authService: CalAngularService, private homeService: HomeService, private loaderService: LoaderService, private changeDetector: ChangeDetectorRef,
    location: Location, private dialogService: DialogService, public router: Router, private assetServiceService: AssetServiceService
  ) 
  {
    this.getAllSecurityUsersAccess();

    this.authService.isUserSignedIn()
      .subscribe((value: any) => {
        this.loggedIn = value;
        if (this.loggedIn) {
          this.currentUserProfile = this.authService.cvxClaimsPrincipal;
         this.homeService.setCurrentUserProfile(this.currentUserProfile);
         localStorage.setItem('currentUserProfile', JSON.stringify(this.currentUserProfile));
        }
        else {
          console.log("user not yet signed in");
        }
      })

      this.loaderChangeSubject = this.loaderService.triggerLoaderChange
      .subscribe(data => {
        if (data) {
          this.IsLoading = data.value;
          //this.changeDetector.detectChanges();
        }
      }, _err => {
        // This is intentional
      });

  }

  getAllSecurityUsersAccess() {
      this.assetServiceService.getAllSecurityUsersAccess()
        .subscribe({
          next: (data) => {
            this.securityUsersAccess = data;
            this.homeService.setSecurityUsersAccess(this.securityUsersAccess);
            localStorage.setItem('securityUsersAccess', JSON.stringify(this.securityUsersAccess));
          },
          error: (error) => {
            console.error('Error:', error);
          },
          complete: () => {
            //console.log('Request complete');
          }
        });
  }
  
}
