
<div class="container_asset">
    <nav>
        <!-- <img src="../../assets/img/image.png" alt=""> -->
    </nav>
    
    <p class="checkingpara"><span style="color: red;">CHECK-OUT</span> : Validate device</p>
    <hr>
    
    <form (ngSubmit)="onSubmitSerial(form)" #form="ngForm">
        <!-- <label for="">Serial Number</label><br /> -->
        <input
        pattern="^[a-zA-Z0-9]+$"
        required 
        maxlength="20" 
        #myInputSerialNumber="ngModel" 
        #myInputReset
        (keydown)="handleKeydown($event)" 
        type="text" 
        ngModel 
        name="serialNumber" 
        autofocus 
        placeholder="Enter Serial Number">
        <button class="validate" type="submit">Validate</button>
        <button type=reset (click)= 'resetForm(form)' class="reset">Reset</button>
    </form>

    <div class="container_output">
      @for (obj of resultConsult; track obj.id) {
        <p class="Outputlabel">User information: </p>
        <label class="Outputlabel">Serial Number:</label> <span> {{obj.serialnumber}}</span><br>
        <label class="Outputlabel">Assigned User:</label> <span> {{obj.assignedtoValue}}</span><br>
        <label class="Outputlabel">Alternate / Supervisor: </label> <span> {{obj.managedbyValue}}</span><br>
        <label class="Outputlabel">Device Model: </label> <span> {{obj.modelValue}}</span><br>
        <label class="Outputlabel">Device Status: </label> <span> {{obj.state}}</span>
      }
      @empty {
        @if(myInputSerialNumber.invalid && myInputSerialNumber.touched){
          <!-- certian action -->
        }@else if (stateFound) {
          <p style="color: red; font-size: 20px">No data found. Please verify the serial number.</p>
        }
    }  
      
    </div>
    
    <div class="Check-inSection">
      <button type="submit" class="check-out" [disabled]="myInputSerialNumber.invalid" (click)="onCheckOut()">Check-out</button>
      <button type="" class="investigate reset" [disabled]="myInputSerialNumber.invalid" (click)="onInvestigate()">Investigate</button>
    </div>
    
</div>

