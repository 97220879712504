import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, APP_INITIALIZER, NgModule } from '@angular/core';
import { CalClient } from '@cvx/cal';
export * from '@cvx/cal';
import { from, firstValueFrom } from 'rxjs';
import { CommonModule } from '@angular/common';
import { UrlString, BrowserUtils } from '@azure/msal-browser';
import * as i1 from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
const CAL_CONFIG = new InjectionToken('CAL_CONFIG');
const CAL_INSTANCE = new InjectionToken('CAL_INSTANCE');
class CalAngularService {
  constructor(calConfig) {
    this.calConfig = calConfig;
    this._config = this.calConfig;
    this._cal = new CalClient(this._config);
  }
  get cvxClaimsPrincipal() {
    return this._cal.cvxClaimsPrincipal;
  }
  isUserSignedIn() {
    return from(this._cal.isUserSignedIn());
  }
  userInitiatedSignIn() {
    return from(this._cal.userInitiatedSignIn());
  }
  userInitiatedSignOut() {
    return from(this._cal.userInitiatedSignOut());
  }
  isInGroup(groupCheck) {
    return from(this._cal.isInGroup(groupCheck));
  }
  getUserPropertiesFromMsGraph(properties) {
    return from(this._cal.getUserPropertiesFromMsGraph(properties));
  }
  getAADToken(oidcScopes = this._config.oidcScopes, fullAuthResponse = false) {
    return from(this._cal.getAADToken(oidcScopes, fullAuthResponse));
  }
  getAccount() {
    return this._cal.getAccount();
  }
  getIdTokenClaims() {
    return this._cal.getIdTokenClaims();
  }
  async getClaims() {
    return this._cal.getClaims();
  }
  getAccessTokenFromCache() {
    return this._cal.getAccessTokenFromCache();
  }
  static {
    this.ɵfac = function CalAngularService_Factory(t) {
      return new (t || CalAngularService)(i0.ɵɵinject(CAL_CONFIG));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CalAngularService,
      factory: CalAngularService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CalAngularService, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CAL_CONFIG]
    }]
  }], null);
})();
class CalGuardService {
  constructor(calConfig, authService) {
    this.calConfig = calConfig;
    this.authService = authService;
  }
  async canActivate() {
    if (UrlString.hashContainsKnownProperties(window.location.hash) && BrowserUtils.isInIframe()) {
      console.warn('redirectUri is set to a page with CAL Guard. It is recommended to not set redirectUri to a page that requires authentication.');
      return false;
    }
    this.authService.isUserSignedIn().subscribe(signedIn => {
      if (!signedIn) {
        this.authService.userInitiatedSignIn().subscribe(claims => {
          if (claims) {
            return true;
          }
          return false;
        });
      }
      return true;
    });
    return firstValueFrom(this.authService.isUserSignedIn());
  }
  static {
    this.ɵfac = function CalGuardService_Factory(t) {
      return new (t || CalGuardService)(i0.ɵɵinject(CAL_CONFIG), i0.ɵɵinject(CalAngularService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CalGuardService,
      factory: CalGuardService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CalGuardService, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CAL_CONFIG]
    }]
  }, {
    type: CalAngularService
  }], null);
})();
class RoleGuardService {
  constructor(calConfig, authService) {
    this.calConfig = calConfig;
    this.authService = authService;
  }
  canActivate(next) {
    let roles = this.authService.cvxClaimsPrincipal.roles;
    if (!roles || !roles.some(role => next.data['roles'].includes(role))) {
      return false;
    }
    return true;
  }
  static {
    this.ɵfac = function RoleGuardService_Factory(t) {
      return new (t || RoleGuardService)(i0.ɵɵinject(CAL_CONFIG), i0.ɵɵinject(CalAngularService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: RoleGuardService,
      factory: RoleGuardService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RoleGuardService, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CAL_CONFIG]
    }]
  }, {
    type: CalAngularService
  }], null);
})();
class ConfigService {
  constructor(httpHandler) {
    this.http = new HttpClient(httpHandler);
  }
  init(endpoint) {
    return new Promise((resolve, reject) => {
      this.http.get(endpoint).pipe(map(result => result)).subscribe({
        next: value => {
          this.settings = value;
          resolve(true);
        },
        error: error => {
          reject(error);
        }
      });
    });
  }
  getSettings(key) {
    if (!key || Array.isArray(key) && !key[0]) {
      return this.settings;
    }
    if (!Array.isArray(key)) {
      key = key.split('.');
    }
    return key.reduce((account, current) => account && account[current], this.settings);
  }
  static {
    this.ɵfac = function ConfigService_Factory(t) {
      return new (t || ConfigService)(i0.ɵɵinject(i1.HttpBackend));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ConfigService,
      factory: ConfigService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConfigService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpBackend
  }], null);
})();
const AUTH_CONFIG_URL_TOKEN = new InjectionToken('AUTH_CONFIG_URL');
function initializerFactory(env, configUrl) {
  const promise = env.init(configUrl).then(() => {
    console.log('finished getting configurations dynamically.');
  });
  return () => promise;
}
function CALConfigFactory(config) {
  return {
    autoSignIn: config.getSettings('autoSignIn'),
    popupForLogin: config.getSettings('popupForLogin'),
    cacheLocation: config.getSettings('cacheLocation'),
    instance: config.getSettings('instance'),
    tenantId: config.getSettings('tenantId'),
    clientId: config.getSettings('clientId'),
    redirectUri: config.getSettings('redirectUri'),
    oidcScopes: config.getSettings('oidcScopes'),
    graphScopes: config.getSettings('graphScopes')
  };
}
class CalAngularModule {
  static forRoot(config) {
    if (typeof config === 'string' || config instanceof String) {
      return {
        ngModule: CalAngularModule,
        providers: [ConfigService, {
          provide: AUTH_CONFIG_URL_TOKEN,
          useValue: config
        }, {
          provide: APP_INITIALIZER,
          useFactory: initializerFactory,
          deps: [ConfigService, AUTH_CONFIG_URL_TOKEN],
          multi: true
        }, {
          provide: CAL_CONFIG,
          useFactory: CALConfigFactory,
          deps: [ConfigService]
        }, CalAngularService]
      };
    }
    return {
      ngModule: CalAngularModule,
      providers: [{
        provide: CAL_CONFIG,
        useValue: config
      }, CalAngularService]
    };
  }
  static {
    this.ɵfac = function CalAngularModule_Factory(t) {
      return new (t || CalAngularModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CalAngularModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [CalGuardService, RoleGuardService],
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CalAngularModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [],
      providers: [CalGuardService, RoleGuardService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of cal-angular
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CALConfigFactory, CalAngularModule, CalAngularService, CalGuardService, ConfigService, RoleGuardService, initializerFactory };
