import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent {

  title: string = "";
  message: string = "";
  isError: boolean = false;


  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.BindDetail(data);
    }
  }

  BindDetail(data: any) {
    this.isError = data.isError;
    this.title = data.isError ? "Error" : "Success!";
    this.message = data.message;
  }

  OnClose() {
    this.dialogRef.close();
  }

}
