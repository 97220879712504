import { Component } from '@angular/core';
import { AssetServiceService } from '../../services/asset-service.service';

@Component({
  selector: 'app-admin-home',
  standalone: true,
  imports: [],
  templateUrl: './admin-home.component.html',
  styleUrl: './admin-home.component.css'
})
export class AdminHomeComponent {
  checkoutCount: number;
  filters = {
    length: 5,
    pageNumber: 1,
    serialNumber: "",
    assetOwner: "",
    initialDate: Date.now(),
    finishDate: ""
  };
 
  constructor(private assetServiceService: AssetServiceService) {
    this.getAssetMovementType(2);
    
  }

  getAssetMovementType(movementTypeId: number){
    return this.assetServiceService.getAssetMovementType(movementTypeId, this.filters).subscribe(
      {
        next: (data) => {
          this.checkoutCount = data.length;
          
        },
        error: (error) => {
          console.error('Error:', error);
        },
        complete: () => {
          //console.log('Request complete');
        }
      }
    )
  }
}
