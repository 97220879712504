import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  assetInfo: any;
  filter: any;


  constructor() { }

  setassetInfo(assetInfo: string){
    this.assetInfo = assetInfo;
    
  }

  getassetInfo(){
    return this.assetInfo;
  }

  setFilter(filter: any){
    this.filter = filter;
    
  }

  getFilter(){
    return this.filter;
  }

}
