import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogComponent } from '../shared/alert-dialog/alert-dialog.component';
//import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { CustomConfirmDialogComponent } from '../shared/custom-confirm-dialog/custom-confirm-dialog.component';
import { InvestigateDialogComponent } from '../laptop/investigate-dialog/investigate-dialog.component';
import { EntryPointComponent } from '../laptop/entry-point/entry-point.component';
import { ViewAssetMovementPopUpComponent } from '../admin/view-asset-movement-pop-up/view-asset-movement-pop-up.component';



@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private matDialog: MatDialog) { }

  openAlertDialog(message: string, isError: boolean): MatDialogRef<AlertDialogComponent, any> {
    return this.matDialog.open(AlertDialogComponent, {
      width: '30%',
      data: { message: message, isError: isError },
    });
  }

  // openConfirmDialog(message: string): MatDialogRef<ConfirmDialogComponent, any> {
  //   return this.matDialog.open(ConfirmDialogComponent, {
  //     width: '30%',
  //     data: { message: message },
  //   });
  // }

  openCustomConfirmDialog(message: string): MatDialogRef<CustomConfirmDialogComponent, any> {
    return this.matDialog.open(CustomConfirmDialogComponent, {
      width: '30%',
      data: { message: message },
    });
  }

  investigateDialog(message: string, isError: boolean): MatDialogRef<InvestigateDialogComponent, any> {
    return this.matDialog.open(InvestigateDialogComponent, {
      width: '30%',
      data: { message: message, isError: isError },
    });
  }

  viewAssetMovementInfo(message: string, isError: boolean): MatDialogRef<ViewAssetMovementPopUpComponent, any> {
    return this.matDialog.open(ViewAssetMovementPopUpComponent, {
      width: '30%',
      data: { message: message, isError: isError },
    });
  }

  EntryPointDialog(message: string, isError: boolean): MatDialogRef<EntryPointComponent, any> {
    return this.matDialog.open(EntryPointComponent, {
      width: '30%',
      data: { message: message, isError: isError },
      disableClose: true
    });
  }
}
