import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-custom-confirm-dialog',
  templateUrl: './custom-confirm-dialog.component.html',
  styleUrls: ['./custom-confirm-dialog.component.css']
})
export class CustomConfirmDialogComponent {

  title: string = "";
  message: string = "";

  constructor(
    public dialogRef: MatDialogRef<CustomConfirmDialogComponent>, private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.BindDetail(data);
    }

  }

  BindDetail(data: any) {
    this.title = "Confirm";
    this.message = data.message;
  }

  OnConfirm() {
    //Yes Event
    this.dialogRef.close({ flag: 1 });
  }

  OnCancel() {
    //Cancel Event
    this.dialogRef.close({ flag: -1 });
  }

  OnClose() {
    //No Event
    this.dialogRef.close({ flag: 0 });
  }

}
