<div class="modal1">

    <div class="row mb-2">
      <div class="col alert-title">
        <h2 style="text-align: center;">{{title}}</h2>
        <div class="container_output">
            <div><label class="Outputlabel">Serial Number:</label> <span> {{assetInfo.assetSerialNumber}}</span></div>
            <div><label class="Outputlabel">Assigned User:</label> <span> {{assetInfo.assetOwner1}}</span></div>
            <div><label class="Outputlabel">Alternate / Supervisor::</label> <span> {{assetInfo.assetOwner2}}</span></div>
            <div><label class="Outputlabel">CheckPoint Name:</label> <span> {{assetInfo.checkPointName}}</span></div>
            <div><label class="Outputlabel">Movement Type:</label> <span> </span></div>
            <div><label class="Outputlabel">Security Operator:</label> <span> {{assetInfo.securityOperator}}</span></div>
            <div><label class="Outputlabel">Investigation Comment:</label> <span> {{assetInfo.investigationComments}}</span></div>
            <div><label class="Outputlabel">Created By:</label> <span> {{assetInfo.createdBy}}</span></div>
            <div><label class="Outputlabel">Created Date:</label> <span> {{assetInfo.createDate}}</span></div>
            <div><label class="Outputlabel">Modify Date:</label> <span> {{assetInfo.modifyDate}}</span></div>
            <div><label class="Outputlabel">Movement Date:</label> <span> {{assetInfo.movementDate}}</span></div>
        </div>      
      </div>
    </div>
  
    <div class="row mt-2 d-flex justify-content-end">
      <div class="col">
        <button type="button" class="btn alert-btn-primary float-end" (click)="OnClose()">OK</button>
      </div>
    </div>
  
  </div>
  