import { Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { HomePageComponent } from './laptop/home-page/home-page.component';
import { HomeComponent } from './laptop/home/home.component';
import { CheckInComponent } from './laptop/check-in/check-in.component';
import { CheckOutComponent } from './laptop/check-out/check-out.component';
import { AdminCheckInComponent } from './admin/admin-check-in/admin-check-in.component';
import { AdminCheckOutComponent } from './admin/admin-check-out/admin-check-out.component';
import { AdminInvestigateComponent } from './admin/admin-investigate/admin-investigate.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';


export const routes: Routes = [
    { path: 'admin', 
        component:  AdminComponent,
        children: [
            {
                path: '', component: AdminHomeComponent, pathMatch: 'full'
            },
            {
                path:'admin-checkin', component: AdminCheckInComponent
            },
            {
                path: 'admin-checkout', component: AdminCheckOutComponent
            },
            {
                path: 'admin-investigate', component: AdminInvestigateComponent
            }
        ]
    },
    { path: '',
        component:  HomeComponent,
        children:[
            {
                path: 'check-in', component: CheckInComponent,
            },
            {
                path: 'check-out', component: CheckOutComponent
            },
            {
                path: '', component: HomePageComponent, pathMatch: 'full'
            },
        ]
    },
];
