<div class="modal1">

  <div class="row mb-2">
    <div class="col alert-title">
      <h2>{{title}}</h2>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <span>{{message}}</span>
    </div>
  </div>

  <div class="row mt-2 d-flex justify-content-end">
    <div class="col">
      <button type="button" class="btn alert-btn-primary float-end" (click)="OnClose()">OK</button>
    </div>
  </div>

</div>
