import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../shared/alert-dialog/alert-dialog.component';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-view-asset-movement-pop-up',
  standalone: true,
  imports: [],
  templateUrl: './view-asset-movement-pop-up.component.html',
  styleUrl: './view-asset-movement-pop-up.component.css'
})
export class ViewAssetMovementPopUpComponent {
  title: string = "";
  message: string = "";
  isError: boolean = false;
  assetInfo: any;


  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private adminService: AdminService) {
    if (data) {
      this.BindDetail(data);
    }
    this.assetInfo = this.adminService.getassetInfo();
  }

  BindDetail(data: any) {
    this.isError = data.isError;
    this.title = data.isError ? "Error" : "Information!";
    this.message = data.message;
  }

  OnClose() {
    this.dialogRef.close();
  }
}
